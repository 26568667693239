/* eslint-disable react/react-in-jsx-scope */
import { addFilter, config } from '@arandu/laravel-mui-admin';
import { Typography } from '@mui/material';


export default () => {

    addFilter('repository_index_item_data', (value, item, column) => {
        // console.log('testing for', item, column);
        if (item.className === 'contract' && column.key === 'status') {
            // console.log('reached', config('boot.data.constants.contractStatuses'));
            return (
                <Typography color="primary" fontWeight="bold">
                    {config('boot.data.constants.contractStatuses')[value]}
                </Typography>
            );
        }
        return value;
    });

    addFilter(
        'repository_index_get_item_actions',
        (actions, item) => {
            if (item.className !== 'contract') {
                return actions;
            }
            return [
                ...actions,
                { name: 'createProject', label: 'Criar Projeto', },
            ];
        }
    );
};
