/* MATERIAL UI ICONS */
import AccountBalance from "@mui/icons-material/AccountBalance";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import Add from "@mui/icons-material/Add";
import AllInbox from "@mui/icons-material/AllInbox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import ArrowNorth from "@mui/icons-material/North";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowSouth from "@mui/icons-material/South";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssistantOutlined from "@mui/icons-material/AssistantOutlined";
import AttachFile from "@mui/icons-material/AttachFile";
import AttachMoney from "@mui/icons-material/AttachMoney";
import BarChartIcon from "@mui/icons-material/BarChart";
import Book from "@mui/icons-material/Book";
import Bookmark from "@mui/icons-material/Bookmark";
import BookmarkOutlined from "@mui/icons-material/BookmarkOutlined";
import BookmarkTwoTone from "@mui/icons-material/BookmarkTwoTone";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import CalculateOutlined from "@mui/icons-material/CalculateOutlined";
import Calendar from "@mui/icons-material/CalendarToday";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Check from "@mui/icons-material/Check";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Close from "@mui/icons-material/Close";
import Construction from "@mui/icons-material/Construction";
import ContactsIcon from "@mui/icons-material/Contacts";
import ControlPoint from "@mui/icons-material/ControlPoint";
import Dashboard from "@mui/icons-material/Dashboard";
import DashboardOutlined from "@mui/icons-material/DashboardOutlined";
import Delete from "@mui/icons-material/Delete";
import DeleteForever from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import Engineering from "@mui/icons-material/Engineering";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterList from "@mui/icons-material/FilterList";
import GavelIcon from "@mui/icons-material/Gavel";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import Https from "@mui/icons-material/Https";
import Inbox from "@mui/icons-material/MoveToInbox";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import List from "@mui/icons-material/List";
import LocationOn from "@mui/icons-material/LocationOn";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import Logout from "@mui/icons-material/Logout";
import LoopIcon from "@mui/icons-material/Loop";
import LowPriority from "@mui/icons-material/LowPriority";
import Mail from "@mui/icons-material/Mail";
import MoreVert from "@mui/icons-material/MoreVert";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import People from "@mui/icons-material/People";
import PeopleOutline from "@mui/icons-material/PeopleOutline";
import PersonAddAltOutlined from "@mui/icons-material/PersonAddAltOutlined";
import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import QuestionAnswerOutlined from "@mui/icons-material/QuestionAnswerOutlined";
import QuestionMarkOutlined from "@mui/icons-material/QuestionMarkOutlined";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import Receipt from "@mui/icons-material/Receipt";
import ReceiptLong from "@mui/icons-material/ReceiptLong";
import Recycling from "@mui/icons-material/Recycling";
import Refresh from "@mui/icons-material/Refresh";
import Remove from "@mui/icons-material/Remove";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import Save from "@mui/icons-material/Save";
import Schedule from "@mui/icons-material/Schedule";
import Search from "@mui/icons-material/Search";
import Security from "@mui/icons-material/Security";
import Settings from "@mui/icons-material/Settings";
import StarRating from "@mui/icons-material/Star";
import UnfoldLessDouble from "@mui/icons-material/UnfoldLessDouble";
import UnfoldMoreDouble from "@mui/icons-material/UnfoldMoreDouble";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Warning from "@mui/icons-material/Warning";
// import TaskIcon from '@mui/icons-material/Task';
// import TaskAltIcon from '@mui/icons-material/TaskAlt';

/* CUSTOM ICONS */
import Block from "../components/Icons/BlockIcon";
import BoardIcon from "../components/Icons/BoardIcon";
import BoardIconGreen from "../components/Icons/BoardIconGreen";
import Bug from "../components/Icons/BugIcon";
import ChatOutline from "../components/Icons/ChatOutlineIcon";
import Clock from "../components/Icons/ClockIcon";
import Copy from "../components/Icons/CopyIcon";
import CopyUrl from "../components/Icons/CopyUrlIcon";
import Discord from "../components/Icons/DiscordIcon";
import Epic from "../components/Icons/EpicIcon";
import ExpandIcon from "../components/Icons/ExpandIcon";
import FacebookIcon from "../components/Icons/FacebookIcon";
import FeedbackIcon from "../components/Icons/FeedbackIcon";
import Figma from "../components/Icons/FigmaIcon";
import Filter from "../components/Icons/FilterIcon";
import GitHub from "../components/Icons/GithubIcon";
import Google from "../components/Icons/GoogleIcon";
import GoogleCalendar from "../components/Icons/GoogleCalendarIcon";
import GoogleDrive from "../components/Icons/GoogleDriveIcon";
import InstagramIcon from "../components/Icons/InstagramIcon";
import LastSubItem from "../components/Icons/LastSubItemIcon";
import LinkedInIcon from "../components/Icons/LinkedinIcon";
import Magnifier from "../components/Icons/SearchIcon";
import MenuIcon from "../components/Icons/MenuIcon";
import PhotoFilterIcon from "../components/Icons/PhotoFilterIcon";
import Project from "../components/Icons/ProjectIcon";
import ProjectGreen from "../components/Icons/ProjectIconGreen";
import RoundMore from "../components/Icons/RoundMoreIcon";
import RoundedCheck from "../components/Icons/RoundedCheckIcon";
import SprintIcon from "../components/Icons/SprintIcon";
import StarsIcon from "../components/Icons/StarsIcon";
import SubItem from "../components/Icons/SubItemIcon";
import SubTask from "../components/Icons/SubtaskIcon";
import Task from "../components/Icons/TaskIcon";
import TaskCheck from "../components/Icons/TaskCheckIcon";
import Trash from "../components/Icons/TrashIcon";
import User from "../components/Icons/UserIcon";
import UserGreen from "../components/Icons/UserIconGreen";
import YouTube from "../components/Icons/YouTubeIcon";

export default {
    accountBalance: AccountBalance,
    accountBalanceWallet: AccountBalanceWallet,
    accountCircleOutlined: AccountCircleOutlined,
    add: Add,
    allInbox: AllInbox,
    arrowDownward: ArrowDownward,
    arrowDropDown: ArrowDropDown,
    arrowDropUp: ArrowDropUp,
    arrowLeft: ArrowLeft,
    arrowNorth: ArrowNorth,
    arrowRight: ArrowRight,
    arrowSouth: ArrowSouth,
    arrowUpward: ArrowUpward,
    assessment: AssessmentIcon,
    assignmentOutlined: AssignmentOutlinedIcon,
    assistantOutlined: AssistantOutlined,
    attachFile: AttachFile,
    attachMoney: AttachMoney,
    barChart: BarChartIcon,
    block: Block,
    board: BoardIcon,
    boardGreen: BoardIconGreen,
    book: Book,
    bookmark: Bookmark,
    bookmarkOutlined: BookmarkOutlined,
    bookmarkTwoTone: BookmarkTwoTone,
    boxUnchecked: CheckBoxOutlineBlankOutlinedIcon,
    boxChecked: CheckBoxOutlinedIcon,
    bug: Bug,
    bugReportOutlined: BugReportOutlinedIcon,
    calculateOutlined: CalculateOutlined,
    calendar: Calendar,
    chat: ChatBubbleOutlineIcon,
    chatOutline: ChatOutline,
    check: Check,
    checkBoxOutlineBlank: CheckBoxOutlineBlank,
    chevronLeft: ChevronLeft,
    chevronRight: ChevronRight,
    clock: Clock,
    close: Close,
    construction: Construction,
    contactsIcon: ContactsIcon,
    controlPoint: ControlPoint,
    copy: Copy,
    copyUrl: CopyUrl,
    dashboard: Dashboard,
    dashboardOutlined: DashboardOutlined,
    delete: Delete,
    deleteForever: DeleteForever,
    discord: Discord,
    download: DownloadIcon,
    edit: EditOutlinedIcon,
    emailOutlined: EmailOutlined,
    engineering: Engineering,
    epic: Epic,
    expand: ExpandIcon,
    expandLess: ExpandLess,
    expandMore: ExpandMore,
    facebook: FacebookIcon,
    feedback: FeedbackIcon,
    figma: Figma,
    fileCopyIcon: FileCopyIcon,
    filter: Filter,
    filterList: FilterList,
    gavelIcon: GavelIcon,
    github: GitHub,
    google: Google,
    googleCalendar: GoogleCalendar,
    googleDrive: GoogleDrive,
    homeOutlined: HomeOutlined,
    inbox: Inbox,
    instagram: InstagramIcon,
    lastSubItem: LastSubItem,
    linkedin: LinkedInIcon,
    list: List,
    locationOn: LocationOn,
    LocalPhoneOutlinedIcon: LocalPhoneOutlinedIcon,
    LocalAtmIcon: LocalAtmIcon,
    lock: Https,
    logout: Logout,
    loop: LoopIcon,
    lowPriority: LowPriority,
    mail: Mail,
    magnifier: Magnifier,
    menu: MenuIcon,
    moreVert: MoreVert,
    newspaperIcon: NewspaperIcon,
    notificationsActiveIcon: NotificationsActiveIcon,
    notificationsOutlined: NotificationsOutlined,
    order: ImportExportIcon,
    people: People,
    peopleOutline: PeopleOutline,
    personAddAltOutlined: PersonAddAltOutlined,
    personPinCircleOutlinedIcon: PersonPinCircleOutlinedIcon,
    photoFilter: PhotoFilterIcon,
    project: Project,
    projectGreen: ProjectGreen,
    questionAnswerOutlined: QuestionAnswerOutlined,
    questionMarkOutlined: QuestionMarkOutlined,
    radioButtonUnchecked: RadioButtonUnchecked,
    receipt: Receipt,
    receiptLong: ReceiptLong,
    recycling: Recycling,
    recurring: RestorePageIcon,
    refresh: Refresh,
    remove: Remove,
    roundedCheck: RoundedCheck,
    roundMore: RoundMore,
    save: Save,
    schedule: Schedule,
    search: Search,
    security: Security,
    settings: Settings,
    sprint: SprintIcon,
    starRating: StarRating,
    stars: StarsIcon,
    subItem: SubItem,
    subTask: SubTask,
    task: Task,
    taskCheck: TaskCheck,
    trash: Trash,
    unfoldLessDouble: UnfoldLessDouble,
    unfoldMoreDouble: UnfoldMoreDouble,
    upload: FileUploadIcon,
    user: User,
    userGreen: UserGreen,
    volumeUp: VolumeUpIcon,
    warning: Warning,
    youtube: YouTube,
};
