/* eslint-disable react/react-in-jsx-scope */
import { addFilter, config } from '@arandu/laravel-mui-admin';

import { Typography } from '@mui/material';

export default () => {
    addFilter('repository_index_item_data', (value, item, column) => {
        // console.log('testing for', item, column);
        if (item.className === 'budget' && column.key === 'status') {
            // console.log('reached', config('boot.data.constants.budgetStatuses'));
            return (
                <Typography color="primary" fontWeight="bold">
                    {config('boot.data.constants.budgetStatuses')[value]}
                </Typography>
            );
        }
        return value;
    });

    addFilter(
        'repository_index_get_item_actions',
        (actions, item) => {
            // console.log(item.contract);

            if (item.className !== 'budget') {
                return actions;
            }

            const newActions = [...actions];

            if (item.status === 2) { // Proposta em Elaboração
                newActions.push({ name: 'createProposal', label: 'Criar Proposta' });
            }

            if (item.status === 1) { // Proposta Criada
                newActions.push({ name: 'approvedProposal', label: 'Proposta Aprovada' });
                newActions.push({ name: 'rejectedProposal', label: 'Proposta Rejeitada' });
            }

            if (item.status === 3) { // Proposta Aprovada
                newActions.push({ name: 'generateContract', label: 'Gerar Contrato' });
                newActions.push({ name: 'viewContract', label: 'Visualizar Contrato' });
            }

            if (item.status >= 1) {
                newActions.push({ name: 'viewProposal', label: 'Visualizar Proposta' });
            }

            if (item.status === 5) { // Contrato Criado
                newActions.push({ name: 'signedContract', label: 'Contrato Assinado' });
                newActions.push({ name: 'viewContract', label: 'Visualizar Contrato' });
            }

            if (item.status === 6) { // Contrato Assinado
                newActions.push({ name: 'generateProject', label: 'Criar Projeto' });
                newActions.push({ name: 'viewContract', label: 'Visualizar Contrato' });
            }

            return newActions;
        }
    );
};
