export default {
    abilities: {
        create: 'Criar',
        read: 'Ler',
        update: 'Atualizar',
        delete: 'Deletar',
        restore: 'Recuperar',
    },
    aria: { openDrawer: 'Abrir' },
    auth: {
        login: 'Entrar',
        logout: { confirm: 'Tem certeza que deseja sair?' },
        password: {
            confirm: 'Confirmar Senha',
            forgot: 'Esqueceu sua senha?',
            new: 'Nova Senha',
            reset: 'Redefinir Senha',
            send: 'Enviar link de redefinição de senha',
        },
        register: 'Criar Conta',
        remember: 'Lembrar-me',
    },
    bug: {
        create: {
            success: 'Bug criado com sucesso!',
            error: 'Erro ao criar bug.',
            validate: 'Nome, responsável e data de entrega são obrigatórios.',
        },
        update: {
            success: 'Bug atualizado com sucesso!',
            error: 'Erro ao atualizar bug.',
        },
    },
    cancel: 'Cancelar',
    comment: {
        success: 'Comentário enviado com sucesso!',
        error: 'Erro ao enviar comentário.',
    },
    common: {
        actions: 'Ações',
        add: 'Adicionar',
        all: 'Todos',
        apply: 'Aplicar',
        attachments: 'Anexos',
        average: 'Média',
        cancel: 'Cancelar',
        clear: 'Limpar',
        delete: 'Deletar',
        deleted: 'Deletado com sucesso!',
        deleteForever: 'Deletar permanentemente',
        duplicate: 'Duplicar',
        duration: 'Duração',
        error: 'Erro ao realizar requisição.',
        export: 'Exportar',
        day: 'dia',
        days: 'dias',
        days_ago: 'dias atrás',
        finish: 'Concluir',
        hour: 'hora',
        hours: 'horas',
        hours_ago: 'horas atrás',
        just_now: 'agora mesmo',
        minute: 'minuto',
        minutes: 'minutos',
        minutes_ago: 'minutos atrás',
        new: 'Criar',
        newGroup: 'Nova seção',
        newQuestion: 'Nova pergunta',
        no: 'Não',
        none: 'Nenhuma',
        noResults: 'Não há resultados para exibir.',
        project: 'Projeto',
        required: 'Este campo é obrigatório',
        restore: 'Restaurar',
        restored: 'Restaurado com sucesso!',
        saved: 'Dados salvos com sucesso!',
        search: 'Buscar',
        second: 'segundo',
        seconds: 'segundos',
        seconds_ago: 'segundos atrás',
        submit: 'Salvar',
        softDelete: 'Enviar para lixeira',
        startDate: 'Data de Início',
        task: 'Tarefa',
        tasks: 'Tarefas',
        title: 'Título',
        unsavedChanges: 'Existem alterações não salvas. Deseja mesmo sair?',
        update: 'Atualizar',
        yes: 'Sim',
    },
    copyUrl: {
        success: 'URL copiada para a área de transferência!',
        error: 'Erro ao copiar URL.',
    },
    dashboard: {
        export: {
            title: 'Exportar Dados',
            message: 'Deseja exportar todos os dados dessa dashboard?',
        },
        widgets: {
            week_tasks: 'Tarefas da semana',
            week_hours: 'Horas da semana',
        },
        workedHours: 'Horas trabalhadas',
        notes: {
            title: 'Anotações Privadas',
            placeholder: 'Escreva uma breve anotação e adicione um link',
        },
    },
    dependents: {
        epic: {
            label: 'Épicos Dependentes',
            tasks: {
                label: 'Tarefas Deste Épico e Duração',
                noData: 'Nenhuma tarefa',
            }
        },
        task: {
            label: 'Tarefas Dependentes',
            tasks: {
                label: 'SubTarefas Desta Tarefa e Duração',
                noData: 'Nenhuma sub-tarefa',
            }
        },
    },
    error: 'Erro',
    forms: {
        new: 'Novo formulário',
        addGroup: 'Adicionar nova seção',
        addQuestion: 'Adicionar nova pergunta',
        buttons: {
            add: 'Adicionar',
            remove: 'Remover',
            addSGroup: 'Adicionar seção',
            addQuestion: 'Adicionar pergunta',
            addOption: 'Adicionar opção',
            addOthers: 'adicionar "Outro"',
            markAll: 'Todos os marcadores',
        },
        placeholders: {
            title: 'Título do formulário',
            description: 'Descrição do formulário',
            group: {
                title: 'Título do formulário',
                subtitle: 'Título da seção (opcional)',
                description: 'Descrição da seção',
                descriptionOpt: 'Descrição (opcional)',
            },
            question: {
                title: 'Pergunta',
                text: 'Texto de resposta curta',
                textarea: 'Texto de resposta longa',
                option: 'Texto da opção',
                scale: 'Marcador (opcional)',
                helperText: 'Texto auxiliar (opcional)',
            },
        },
        questionType: {
            text: 'Resposta curta',
            textarea: 'Resposta longa',
            radio: 'Múltipla escolha',
            checkbox: 'Caixas de seleção',
            select: 'Lista suspensa',
            scale: 'Escala linear',
            datetime: 'Data e hora',
        },
        nextGroup: 'Após a seção',
        goToGroup: 'Ir para a seção',
    },
    kanban: {
        column: {
            loading: {
                status: 'Carregando colunas...',
                error: 'Erro ao carregar dados da coluna',
            },
        },
        updated: 'Kanban atualizado com sucesso!',
    },
    loading: 'Carregando...',
    models: {
        role: {
            plural: 'Funções',
            singular: 'Função',
        },
        user: {
            plural: 'Usuários',
            singular: 'Usuário',
        },
        address: {
            plural: 'Endereços',
            singular: 'Endereço',
        },
        customer: {
            plural: 'Clientes',
            singular: 'Cliente',
        },
        account: {
            plural: 'Contas',
            singular: 'Conta',
        },
        transaction: {
            plural: 'Transações',
            singular: 'Transação',
        },
        cost_center: {
            plural: 'Centros de custo',
            singular: 'Centro de custo',
        },
        input: {
            plural: 'Insumos',
            singular: 'Insumo',
        },
        composition: {
            plural: 'Composições',
            singular: 'Composição',
        },
        contract: {
            plural: 'Contratos',
            singular: 'Contrato',
        },
        budget: {
            plural: 'Orçamentos',
            singular: 'Orçamento',
        },
        planning: {
            plural: 'Planejamentos',
            singular: 'Planejamento',
        },
        project: {
            plural: 'Projetos',
            singular: 'Projeto',
        },
        timesheet: {
            plural: 'Apontamentos',
            singular: 'Apontamento',
        },
        task: {
            plural: 'Tarefas',
            singular: 'Tarefa',
        },
        feedback: {
            plural: 'Feedbacks',
            singular: 'Feedback',
        },
        invoice: {
            plural: 'Faturas',
            singular: 'Fatura',
        },
        form: {
            plural: 'Formulários',
            singular: 'Formulário',
        },
        response: {
            plural: 'Respostas',
            singular: 'Resposta',
        },
        notice: {
            plural: 'Avisos',
            singular: 'Aviso',
        },
        lead: {
            plural: 'Leads',
            singular: 'Lead',
        },
    },
    navigate: {
        back: 'Voltar',
        home: 'Início',
        logout: 'Sair',
        menu: 'Menu',
        profile: 'Perfil',
        settings: 'Configurações',
        submit: 'Enviar',
    },
    no: 'Não',
    profile: {
        email: 'Alterar e-mail',
        name: 'Nome',
        password: 'Alterar senha',
        passwordConfirm: 'Confirmar nova senha',
        submit: 'Salvar',
        success: 'Perfil atualizado com sucesso!',
        title: 'Editar Perfil',
    },
    roles: {
        admin: 'Administrador',
        subscriber: 'Assinante',
        manager: 'Gerente',
        commercial: 'Comercial',
    },
    table: {
        actions: {
            delete: {
                title: 'Excluir dado',
                confirm: 'Deseja mesmo excluir esse dado?',
            },
            restore: {
                title: 'Recuperar dado',
                confirm: 'Deseja mesmo recuperar esse dado?',
            },
            title: 'Ações',
            select: 'Selecionar ação',
            selectAll: 'Selecionar todos',
            submit: 'Aplicar',
            export: {
                title: 'Exportar dados',
                confirm: 'Deseja mesmo exportar esses dados?',
            }
        },
        cantEditTrashed: 'Não é possível editar dados enviados para a lixeira. Restaure o item para editá-lo.',
        columns: {
            name: 'Nome',
            abilities: 'Permissões',
            role: 'Papel',
        },
        rowsPerPage: 'Exibir por Página:',
        trashed: 'Lixeira',
    },
    tag: {
        block: 'Bloqueada',
        project: 'Projeto',
        priority: 'Prioridade',
        task: 'Tarefa',
    },
    task: {
        bugs: {
            noData: 'Nenhum bug',
            label: 'Bugs',
            count: 'Bugs em aberto:', 
        },
        types: {
            epic: 'Épico',
            task: 'Tarefa',
            sub_task: 'Sub Tarefa',
            bug: 'Bug',
            block: 'Bloqueada',
        },
        comments: {
            noData: 'Nenhum comentário',
            label: 'Comentários',
            placeholder: 'Escreva novo comentário',
            submit: 'Enviar',
        },
        create: {
            success: 'Tarefa criada com sucesso!',
            error: 'Erro ao criar tarefa.',
        },
        delete: {
            success: 'Tarefa excluída com sucesso!',
            error: 'Erro ao excluir tarefa.',
        },
        duplicate: {
            success: 'Tarefa duplicada com sucesso!',
            error: 'Erro ao duplicar tarefa.',
        },
        finish: {
            success: 'Tarefa concluída com sucesso!',
            error: 'Erro ao concluir tarefa.',
        },
        follow: {
            success: 'Tarefa seguida com sucesso!',
            error: 'Erro ao seguir tarefa.',
        },
        movement: {
            success: 'Tarefa movida com sucesso!',
            error: 'Erro ao mover tarefa.',
        }, 
        notFound: 'Tarefa não encontrada.',
        team: 'Equipe da Tarefa',
        update: {
            success: 'Tarefa atualizada com sucesso!',
            error: 'Erro ao atualizar tarefa.',
        },
    },
    undefined: {
        attachments: 'Sem anexos',
        budget: 'Sem orçamento',
        comments: 'Sem comentários',
        customer: 'Sem cliente',
        datetime: 'Sem data',
        'datetime-local': 'Sem data',
        date: 'Sem data',
        time: 'Sem hora',
        default: 'Não disponível',
        dependents: 'Sem tarefas dependentes',
        description: 'Sem descrição',
        drive: 'Sem link do drive',
        due_date: 'Sem data de entrega',
        figma: 'Sem link da interface',
        start_time: 'Sem data de início',
        end_time: 'Sem data de fim',
        recurring_type: 'Sem tipo de Recorrência',
        scope: 'Sem descrição', // 'Sem escopo',
        name: 'Sem nome',
        parent: 'Sem tarefa pai',
        priority: 'Sem prioridade',
        project: 'Sem projeto',
        sprint: 'Sem sprint',
        status: 'Sem status',
        tasks: 'Sem tarefas',
        timesheets: 'Sem apontamentos',
        users: 'Sem responsáveis',
        managers: 'Sem gerentes',
        tag: 'Sem tag',
        contracted_hours: 'Sem horas contratadas',
    },
    menu_categories: {
        daily: 'Dia a Dia',
        management: 'Gestão',
        undefined: 'Indefinido',
    },
    user: {
        email: 'E-mail',
        name: 'Nome',
        password: 'Senha',
    },
    verification: {
        check: 'Verifique seu email',
        notice: 'Antes de continuar, procure na caixa de entrada do'
            + ' seu e-mail para um link de verificação.',
        notReceived: 'Se você não recebeu o e-mail, clique no botão abaixo.',
        resendButton: 'Enviar email novamente',
        resent: 'Um novo link de verificação foi enviado para o seu endereço de e-mail.',
    },
    vote: {
        create: {
            success: 'Voto criado com sucesso!',
            error: 'Erro ao criar voto.',
        },
        update: {
            success: 'Voto atualizado com sucesso!',
            error: 'Erro ao atualizar voto.',
        }, 
        delete: {
            success: 'Voto excluído com sucesso!',
            error: 'Erro ao excluir voto.',
        }, 
    }, 
    recurring_type: {
        daily: 'Diariamente',
        weekly: 'Semanalmente',
        biweekly: 'Quinzenalmente',
        monthly: 'Mensalmente',
    },
    welcome: 'Bem-vindo',
    yes: 'Sim',
};
